
import {computed, defineComponent, inject} from 'vue';
import store from '@/store'
import {useRouter} from "vue-router";


export default defineComponent({
  name: 'Users',
  props: {
    overlay: {
      type: Boolean,
      default: false,
    }
  },
  setup() {
    const icon = "fas fa-user-circle"
    const user = computed(() => store.getters['users/getCurrentUser']);
    const photoSrc = computed(() => store.getters['users/getPhoto'] || require('@/assets/img/users.png'));

    const username = computed(() => {
      let temp = '';
      if (user.value?.lastname) {
        temp = temp + user.value?.lastname + ' '
      }
      if (user.value?.firstname) {
        temp = temp + user.value?.firstname + ' '
      }
      if (user.value?.middlename) {
        temp = temp + user.value?.middlename
      }
      if (!user.value?.middlename && !user.value?.firstname && !user.value?.lastname) {
        temp = 'Авторизуйтесь!'
      }
      return temp
    });

    const router = useRouter();
    const signIn = () => {
      router.push({name: 'Login'});
    };

    const userAction = inject('userAction') ;
    const userExit = inject('userExit');

    return {
      username,
      user,
      icon,
      userAction,
      signIn,
      userExit,
      photoSrc
    }
  }
  });

