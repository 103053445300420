import actions from '@/store/modules/root/actions';
import mutations from '@/store/modules/root/mutations';
import getters from '@/store/modules/root/getters';
import state from "@/store/modules/root/state";


export const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}

export default module