import {ActionContext} from 'vuex';
import {IRootState as CurrentState} from './types';
import {IRootState} from '../../types';


type UsersContext = ActionContext<CurrentState, IRootState>;

export const actions = {

};

export default actions