<template>
    <div class="flex justify-content-start flex-column align-items-start z-w-100 z-h-100">
        <input type="file" ref="file" style="display: none" @change="previewFiles" accept=".jpg,.jpeg,.png">
        <div class="grid field">
            <Button class="p-button-sm mr-2" @click="$refs.file.click()" icon="pi pi-plus" iconPos="right" v-tooltip.bottom="'Выбрать изображение'" />
            <Button class="p-button-sm mr-2" @click="savePhoto()" icon="pi pi-save" iconPos="right" v-tooltip.bottom="'Сохранить изображение'" />
        </div>
        <ImageCropper v-if="showCropper" :src="previewImage"/>
    </div>
</template>

<script>
import {defineComponent, onMounted, ref, provide, inject} from 'vue';
import ImageCropper from '@/components/ImageCropper';
import store from "@/store";

export default  defineComponent ({
    name: "ChangePhoto",
    components: {
        ImageCropper
    },
    setup() {

        onMounted(() => {
            file.value.click();
        });

        const previewImage = ref();
        const showCropper = ref(false);
        const file = ref();

        const fileObject = ref();
        provide('fileObject', fileObject);

        const showAddImage = inject('showAddImage');

        const previewFiles = (e) => {
            showCropper.value = false;
            fileObject.value = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(fileObject.value);
            reader.onload = (e) => {
                previewImage.value = e.target.result;
                showCropper.value = true;
            };
        };

        const croppData = ref();
        provide('croppData', croppData);

        const api = inject('api');

        const checkToken = inject('checkToken');

        const savePhoto = () => {
            checkToken().then(() => {
                api.apiUsers.savePhoto(croppData.value).then(() => {
                    store.dispatch('users/loadPhotoUser');
                    showCropper.value = false;
                    showAddImage.value = false;
                });
            });
        }

        return {
            previewImage,
            showCropper,
            file,
            previewFiles,
            savePhoto
        }
    }
});

</script>

<style lang="scss" scoped>

.canvas {
    width: 75vw;
    height: 75vh;
    background: rgba(105, 105, 105, 0.65);
    overflow: auto;
}

</style>