import {instance, instanceFile, instanceRef} from "./instance";
import apiLogin from "./modules/login"
import apiUsers from "./modules/users"
import apiGame from "./modules/game"
import apiAudit from "./modules/audit"

export default {
    apiLogin: apiLogin(instance, instanceRef),
    apiUsers: apiUsers(instance, instanceFile),
    apiGame: apiGame(instance),
    apiAudit: apiAudit(instance, instanceFile)
}