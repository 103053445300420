import { AxiosInstance } from 'axios'
import {TAuditFilePayload, TAuditPayload} from './types'
import {TApiAudit} from "@/api/types";


export default function (instance: AxiosInstance, instanceFile: AxiosInstance): TApiAudit {
    return {
        getAudit(payload: TAuditPayload) {
            const path = `/audit/items`;
            const val = {params: payload};
            return instance.get(path, val)
        },
        downloadAudit(payload: TAuditFilePayload) {
            const path = `/audit/download_report`;
            const val = {params: payload};
            return instanceFile.get(path, val)
        },
        setUserOnline() {
            const path = `/audit/set_user_online`;
            return instance.post(path)
        },
        getUsersOnline() {
            const path = `/audit/get_users_online`;
            return instance.get(path)
        },
     }
}