<template>
    <Toast class="mt-8" />
    <div class="flex justify-content-start align-items-start z-w-100 z-h-100">
        <GamePrelouder :loading="isLoading">
            <div class="vision-game" :class="{'vision-game-without-header': !showHeader}">
                <div class="vision-game-header">
                    <GameHeader v-if="showHeader" :itemMenu="itemMenu"></GameHeader>
                </div>

                <div class="vision-game-main">
                    <router-view/>
                </div>
            </div>
        </GamePrelouder>
    </div>

    <Dialog header="Личный кабинет"
            v-model:visible="showUser"
            :breakpoints="{'960px': '75vw', '640px': '90vw'}"
            :style="{minWidth: '75vw'}"
            :modal="true"
    >
        <PersonalAccount />
    </Dialog>
</template>

<script>
import {onBeforeMount, defineComponent, computed, watch, provide, ref, onMounted, onBeforeUnmount} from "vue";
import GameHeader from "@/components/header/GameHeader";
import {useRoute, useRouter} from "vue-router";
import store from "@/store";
import PersonalAccount from "@/components/users/PersonalAccount";
import GamePrelouder from "@/components/GamePrelouder.vue";

export default defineComponent({
    name: 'App',
    components: {
        GamePrelouder,
        GameHeader,
        PersonalAccount
    },
    setup() {
        onBeforeMount(async () => {
            store.commit('root/setLoadingStatus', true);
            buildData();
            checkToken(true).then(() => {
                loadState();
            }).catch((e) => {
                console.log('checkToken catch', e);
            }).finally(() => {
                store.commit('root/setLoadingStatus', false);
            });
        });

        onMounted(() => {
           activateActivityTracker();
        });

        onBeforeUnmount(() => {
            removeActivityTracker();
        })

        const loadState = async () => {
            await store.dispatch('users/loadCurrentUser');
            await store.dispatch('users/loadPhotoUser');
            await store.dispatch('games/loadGamesClassifier');
            store.commit('root/setLoadingStatus', false);
        }

        const isLoading = computed(() => store.getters['root/getLoadingStatus']);
        const route = useRoute();
        const router = useRouter();
        const showHeader = computed(() => {
            return !['/login', '/game/volume', '/game/palitra', '/game_intro/volume', '/game_intro/palitra'].includes(route.path)
        });
        const user = computed(() => store.getters['users/getCurrentUser']);
        const itemMenu = computed(() => {
            let menu = [
                {name: 'Главная', path: {name: 'Home'}},
                {name: 'Игры', path: {name: 'Games'}},
            ]
            if (user?.value?.is_admin ?? false) {
                menu.push({name: 'Администрирование', path: {name: 'Admin'}});
            }
            menu.push({name: 'Контакты', path: {name: 'Contacts'}});
            return menu
        });

        watch(() => store.getters['login/getUser'], () => {
            if (store.getters['login/getUser'] !== undefined && store.getters['users/getCurrentUser'] === undefined) {
                store.dispatch('users/loadCurrentUser');
            }
        });

        watch(() => store.getters['login/getIsError'], () => {
            if (store.getters['login/getIsError']) {
                router.push({name: 'Login'});
            }
        });

        const checkToken = async (isReload = false) => {
            return new Promise((resolve, reject) => {
                if (isReload) {
                    store.dispatch('login/refresh').then(() => resolve()).catch(() => reject());
                }
                if (store.getters['login/getAccessTokenExpire'] && store.getters['login/getAccessToken']) {
                    const end_date = new Date(store.getters['login/getAccessTokenExpire']);
                    const currentDate = new Date(new Date().toISOString());
                    if (currentDate > end_date) {
                        store.dispatch('login/refresh').then(() => resolve()).catch(() => reject());
                    } else {
                        resolve();
                    }
                } else {
                    store.commit('login/signError');
                    reject();
                }
            });
        };

        const userExit = () => {
            store.commit('login/signOut');
            store.commit('users/setCurrentUser', undefined);
            store.commit('users/setPhoto', undefined);
            router.push({name: 'Login'});
        };

        const showUser = ref(false);
        const userAction = () => {
            showUser.value = !showUser.value;
        };

        provide('userAction', userAction);
        provide('userExit', userExit);
        provide('checkToken', checkToken);

        const buildData = () => {
            store.commit('login/setAccessToken', JSON.parse(localStorage.getItem('access_token'))?.token ?? '')
            store.commit('login/setRefreshToken', JSON.parse(localStorage.getItem('refresh_token'))?.token ?? '')

            store.commit('login/setDateAccess', JSON.parse(localStorage.getItem('access_token'))?.date_create ?? '')
            store.commit('login/setDateRefresh', JSON.parse(localStorage.getItem('refresh_token'))?.date_create ?? '')

            store.commit('login/setExpireAccessToken', JSON.parse(localStorage.getItem('access_token'))?.expire_access_token ?? '')
            store.commit('login/setExpireRefreshToken', JSON.parse(localStorage.getItem('refresh_token'))?.expire_refresh_token ?? '')
        }

        provide('buildData', buildData);

        //region Activity user
        const userActivityTimeout = ref(null);
        const USER_ACTIVITY_THROTTLER_TIME = 30000;


        function userActivityThrottler() {
            if (!userActivityTimeout.value) {
                store.dispatch('audit/updateUsersOnline');
                userActivityTimeout.value = setTimeout(() => {
                    clearTimeout(userActivityTimeout.value);
                    userActivityTimeout.value = null;
                }, USER_ACTIVITY_THROTTLER_TIME);
            }
        }

        function activateActivityTracker() {
            window.addEventListener("mousemove", userActivityThrottler);
            window.addEventListener("scroll", userActivityThrottler);
            window.addEventListener("keydown", userActivityThrottler);
            window.addEventListener("resize", userActivityThrottler);
        }

        function removeActivityTracker() {
            window.removeEventListener("mousemove", userActivityThrottler);
            window.removeEventListener("scroll", userActivityThrottler);
            window.removeEventListener("keydown", userActivityThrottler);
            window.removeEventListener("resize", userActivityThrottler);
        }
        //endregin

        return {
            showHeader,
            itemMenu,
            showUser,
            isLoading
        }
    },
});
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";

.vision-game {
    display: grid;
    width: 100vw;
    height: 100vh;
    grid-template-columns: 1fr;
    grid-template-rows: $header-height 1fr;
    grid-template-areas: "header" "main";
    justify-items: stretch;
    align-items: stretch;

    &-header {
        grid-area: header;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    &-main {
        grid-area: main;
        max-height: calc(100vh - $header-height);
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        overflow: hidden;
    }
}

.vision-game-without-header {
    grid-template-columns: 1fr !important;
    grid-template-rows: 1fr !important;
    grid-template-areas: "main" !important;

    .vision-game-main {
        max-height: 100vh !important;
    }
}


</style>
