import {IGamesState} from './types';
import {TGamesClassifierItem, TRoundPlayed} from "@/api/modules/game/types";


const mutations = {
    setGamesClassifier(state: IGamesState, val: TGamesClassifierItem[]): void {
        state.gamesClassifier = val;
    },
    setRoundPlayed(state: IGamesState, val: TRoundPlayed): void {
        state.roundPlayed = val;
    },
    setReadRoundPlayed(state: IGamesState): void {
        if (state.roundPlayed?.isRead) {
            state.roundPlayed.isRead = true;
        }
    },
    removeRoundPlayed(state: IGamesState,) {
        state.roundPlayed = null;
    },
    setGameClassifierCode(state: IGamesState, val: TGamesClassifierItem[]): void {
        val.forEach((res) => {
            state.gamesClassifierCode[res.code_name] = res;
        });
    }
};

export default mutations