
import {defineComponent, inject, ref} from "vue";

export default defineComponent({
    name: "landing-1",
    emits: ['next-page'],
    setup() {
        const p1 = inject('p1', ref());

        return {
            p1
        }
    }
})
