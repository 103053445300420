import { ILoginState, IUser } from './types';

const getters = {
    getUser: (state: ILoginState): IUser | undefined  => state.user,
    getIsError: (state: ILoginState): boolean => state.isError,
    getAccessToken: (state: ILoginState): string | undefined  => state.access_token,
    getRefreshToken: (state: ILoginState): string | undefined  => state.refresh_token,
    getAccessTokenDate: (state: ILoginState): string | undefined  => state.date_access,
    getRefreshTokenDate: (state: ILoginState): string | undefined  => state.date_refresh,
    getRefreshTokenExpire: (state: ILoginState): string | undefined  => state.expire_refresh_token,
    getAccessTokenExpire: (state: ILoginState): string | undefined  => state.expire_access_token,
}

export default getters