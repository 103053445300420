import { AxiosInstance } from 'axios'
import { IFormDataValue } from './types'
import {TApiLogin} from "@/api/types";


export default function (instance: AxiosInstance, instanceRef: AxiosInstance): TApiLogin {
    return {
        signIn(payload: IFormDataValue) {
            const path = `/auth/login`
            return instance.post(path, payload)
        },
        refresh() {
            const path = `/auth/refresh_token`
            return instanceRef.get(path)
        },
    }
}