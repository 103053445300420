import {ILoginState} from "@/store/modules/login/types";

const state: ILoginState = {
    access_token: '',
    date_access: undefined,
    expire_access_token: undefined,
    refresh_token: '',
    date_refresh: undefined,
    expire_refresh_token: undefined,
    user: undefined,
    isError: false,
    isLoading: false,
};

// const state: ILoginState = {
//     access_token: JSON.parse(localStorage.getItem('access_token') as string)?.token ?? '',
//     date_access: JSON.parse(localStorage.getItem('access_token') as string)?.date_create ?? '',
//     expire_access_token: JSON.parse(localStorage.getItem('access_token') as string)?.expire_access_token ?? '',
//     refresh_token: JSON.parse(localStorage.getItem('refresh_token') as string)?.token ?? '',
//     date_refresh: JSON.parse(localStorage.getItem('refresh_token') as string)?.date_create ?? '',
//     expire_refresh_token: JSON.parse(localStorage.getItem('refresh_token') as string)?.expire_refresh_token ?? '',
//     user: undefined,
//     isError: false,
//     isLoading: false,
// };


export default state