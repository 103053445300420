import {ActionContext} from 'vuex';
import {ILoginState, TRefreshResponse, TSignInResponse} from './types';
import {IRootState} from '../../types';
import {AxiosError, AxiosResponse} from "axios";
import {TLoginPayload} from "@/api/modules/login/types";
import api from "@/api";


type LoginContext = ActionContext<ILoginState, IRootState>;

export const actions = {
    async signIn(context: LoginContext, payload: TLoginPayload): Promise<any> {
        context.commit('signInRequest');
        const formData: any = new FormData;
        const username: string = payload.username !== null ? payload.username : '';
        const pass: string = payload.password !== null ? payload.password : '';
        formData.append('username', username);
        formData.append('password', pass);
        return new Promise((resolve, reject) => {
            api.apiLogin.signIn(formData).then(
                (res: AxiosResponse) => {
                    const payload: TSignInResponse = res.data;
                    context.commit('signIn', payload);
                    const date = new Date();
                    localStorage.setItem('access_token', JSON.stringify({
                        token: payload.access_token,
                        date_create: date,
                        expire_access_token: payload.expire_access_token
                    }));
                    localStorage.setItem('refresh_token', JSON.stringify({
                        token: payload.refresh_token,
                        date_create: date,
                        expire_refresh_token: payload.expire_refresh_token
                    }))
                    // instance.defaults.headers.common['Authorization'] = `Bearer ${payload.access_token}`;
                    // instanceRefresh.defaults.headers.common['Authorization'] = `Bearer ${payload.refresh_token}`;
                    resolve(200);
                }
            ).catch(
                (err: AxiosError) => {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('refresh_token');
                    context.commit('signError');
                    reject(err);
                }
            );
        })
    },
    async refresh(context: LoginContext): Promise<any> {
        context.commit('signInRequest');
        return new Promise ( (resolve, reject) => {
            api.apiLogin.refresh().then(
                (res: AxiosResponse) => {
                    const payload: TRefreshResponse = res.data;
                    const date = new Date();
                    context.commit('signIn', payload);
                    localStorage.setItem('access_token', JSON.stringify({
                        token: payload.access_token,
                        date_create: date,
                        expire_access_token: payload.expire_access_token
                    }));
                    const token = payload.access_token;
                    // instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                    resolve(200);
                }
            ).catch(
                (err: AxiosError) => {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('refresh_token');
                    context.commit('signError');
                    reject(err);
                }
            );
        });
    },
};

export default actions