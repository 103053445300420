import { AxiosInstance } from 'axios'
import {
    TTypeOperation,
    TTypeOperationPayload,
    TUserCreatePayload,
    TUsersPayload,
    TUserUpdatePayload
} from './types'


export default function (instance: AxiosInstance, instanceFile: AxiosInstance) {
    return {
        getUsers(payload: TUsersPayload) {
            let path = `/users/users?page=${payload.page}&size=${payload.size}`;
            if (payload.filters) {
                path = path + `&filters=${JSON.stringify(payload.filters)}`;
            }
            return instance.get(path)
        },
        getUser(user_id: string) {
            const path = `/users/user/${user_id}`;
            return instance.get(path)
        },
        updateUser(user_id: string, payload: TUserUpdatePayload) {
            const path = `/users/user/${user_id}`;
            return instanceFile.patch(path, payload)
        },
        deleteUser(user_id: string) {
            const path = `/users/user/${user_id}`;
            return instance.delete(path)
        },
        loadUser() {
            const path = `/users/current_user`;
            return instance.get(path)
        },
        createUser(payload: TUserCreatePayload) {
            const path = `/users/user`;
            return instanceFile.post(path, payload)
        },
        checkLogin(login: string) {
            const path = `/users/check_login?login=${login}`;
            return instance.get(path)
        },
        checkEmail(email: string) {
            const path = `/users/check_email?email=${email}`;
            return instance.get(path)
        },
        resetPassword(type_operation: TTypeOperation, payload: TTypeOperationPayload) {
            const path = `/users/reset_password/${type_operation}`;
            return instance.post(path, payload)
        },
        savePhoto(payload: FormData) {
            const path = `/users/photo`;
            return instance.post(path, payload)
        },
        getPhoto() {
            const path = `/users/photo`;
            return instance.get(path)
        },
        deletePhoto() {
            const path = `/users/photo`;
            return instance.delete(path)
        }
    }
}