<template>
  <div class="z-card">
    <slot></slot>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "card",
  setup() {

    return {

    }
  }
});
</script>

<style lang="scss" scoped>

.z-card {
    width: 100%;
    height: 100%;
    border-radius: 10px !important;
    //border: 1px solid rgba(0, 57, 178, 0.3);
    background-color: #ffffff;
    box-shadow: 0 4px 10px rgba(0,0,0,.13),0 0 2px rgba(0,0,0,.16),0 2px 6px rgba(0,0,0,.22)!important;
}

</style>