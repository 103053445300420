import {ActionContext} from 'vuex';
import {IAuditState } from './types';
import {IRootState} from '../../types';
import {AxiosError, AxiosResponse} from "axios";
import api from "@/api";
import {TAuditPayload, TAuditResponse, TAuditUserOnline} from "@/api/modules/audit/types";

type AuditContext = ActionContext<IAuditState, IRootState>;

export const actions = {
    async loadAudit(context: AuditContext, payload: TAuditPayload): Promise<any> {
        context.commit('setLoading');
        return new Promise((resolve, reject) => {
            api.apiAudit.getAudit(payload).then((res: AxiosResponse<TAuditResponse>) => {
                context.commit('setUsers', res.data);
                resolve(res);
            }).catch((err: AxiosError) => {
                reject(err);
                context.commit('setError');
            });
        })
    },
    async updateUsersOnline(context: AuditContext): Promise<any> {
        return new Promise((resolve, reject) => {
            api.apiAudit.setUserOnline().then(() => {
                api.apiAudit.getUsersOnline().then((res: AxiosResponse<TAuditUserOnline[]>) => {
                    context.commit('setUsersOnline', res.data);
                }).finally(() => {
                    resolve('');
                })
            }).catch((err: AxiosError) => {
                reject(err);
                context.commit('setError');
            }).finally(() => {
                resolve('');
            });
        })
    },
};

export default actions