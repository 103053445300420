import {IUser, IUsersState, TUsersResponse} from './types';


const mutations = {
    setLoading(state: IUsersState): void {
        state.isLoading = true;
    },
    setUsers(state: IUsersState, payload: TUsersResponse): void {
        state.isError = false;
        state.users = payload.users;
        state.count = payload.count;
        state.isLoading = false;
    },
    setError(state: IUsersState): void {
        state.isError = true;
        state.users = [];
        state.count = 0;
        state.isLoading = false;
    },
    setCurrentUser(state: IUsersState,  payload: IUser): void {
        state.currentUser = payload
    },
    setPhoto(state: IUsersState,  payload: string | any): void {
        state.photo = payload
    }
};

export default mutations