
type valueDate = null | string | Date

const formatDate = (value: valueDate, with_time = false): string | null => {
    if (value == null) {
        return value
    } else {
        if (with_time) {
            value = new Date(value)
            return value.toLocaleDateString('ru-RU', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            });
        } else {
            value = new Date(value)
            return value.toLocaleDateString('ru-RU', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });
        }
    }
};

const getCurrentYear = (): number => {
    const currentDate = new Date()
    return currentDate.getFullYear()
};

// const getDateForAxios = (value) => {
//     if (value == null) {
//         return value
//     } else {
//         value = new Date(value)
//         let day = value.getDay()
//         if (day.length() < 2) {}
//         return `${value.getFullYear()}-${value.getMonth()}-${value.getDay()}`
//     }
// }

export {formatDate, getCurrentYear}