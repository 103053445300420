
import {computed, defineComponent, inject, onMounted, provide, ref} from 'vue';
import store from "@/store";
import {TApi} from "@/api/types";
import ChangePhoto from "@/components/users/ChangePhoto.vue";
import {useConfirm} from "primevue/useconfirm";
import {formatDate} from "@/source/functions/formatDate";
import UsersStatistick from "@/components/users/UsersStatistick.vue";


export default  defineComponent ({
    name: "PersonalAccount",
    methods: {formatDate},
    components: {UsersStatistick, ChangePhoto},
    setup() {

        onMounted(() => {
            // loadData();
            store.dispatch('users/loadPhotoUser');
        });

        const user = computed(() => store.getters['users/getCurrentUser']);
        const api: TApi = inject('api') as TApi;
        // const myChartPoints: any = ref(null);
        // const myChartGames: any = ref(null);
        const showAddImage = ref(false);
        provide('showAddImage', showAddImage);
        const photoSrc = computed(() => store.getters['users/getPhoto'] || require('@/assets/img/users.png'));

        // const chartDataGames = ref({
        //   labels: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        //   datasets: [
        //     {
        //       type: 'line',
        //       label: 'Объем кол-во игр',
        //       borderColor: '#42A5F5',
        //       borderWidth: 2,
        //       fill: false,
        //       data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        //     },
        //     {
        //       type: 'line',
        //       label: 'Палитра кол-во игр',
        //       borderColor: '#ad42f5',
        //       borderWidth: 2,
        //       fill: false,
        //       data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        //     }
        //   ]
        // });
        // const chartDataPoints = ref({
        //   labels: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        //   datasets: [
        //     {
        //       type: 'bar',
        //       label: 'Объем кол-во очков',
        //       backgroundColor: 'rgba(102,187,106,0.53)',
        //       data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        //       borderColor: '#5e5e5e',
        //       borderWidth: 2
        //     },
        //     {
        //       type: 'bar',
        //       label: 'Палитра кол-во очков',
        //       backgroundColor: 'rgba(255,167,38,0.53)',
        //       data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        //       borderColor: '#5e5e5e',
        //       borderWidth: 2
        //     }
        //   ]
        // });

        // const palitraPlace = ref([0, 0]);
        //
        // const volumePlace = ref([0, 0]);

        // const chartOptions = ref({
        //   plugins: {
        //     legend: {
        //       labels: {
        //         color: '#495057'
        //       }
        //     }
        //   },
        //   scales: {
        //     x: {
        //       ticks: {
        //         color: '#495057'
        //       },
        //       grid: {
        //         color: '#ebedef'
        //       }
        //     },
        //     y: {
        //       ticks: {
        //         color: '#495057'
        //       },
        //       grid: {
        //         color: '#ebedef'
        //       }
        //     }
        //   }
        // });

        // const loadData = () => {
        //   if (user.value) {
        //     const payload = {
        //       user_id: user.value.id,
        //       year: selectedYear.value
        //     }
        //     api.apiGame.getUserStatistics(payload).then((res) => {
        //       chartDataGames.value.datasets[0].data = res.data.game_volume.games;
        //       chartDataGames.value.datasets[1].data = res.data.game_palitra.games;
        //       chartDataPoints.value.datasets[0].data = res.data.game_volume.points;
        //       chartDataPoints.value.datasets[1].data = res.data.game_palitra.points;
        //       volumePlace.value = res.data.game_volume.place;
        //       palitraPlace.value = res.data.game_palitra.place;
        //       statYear.value = res.data.years;
        //       myChartPoints.value.reinit();
        //       myChartGames.value.reinit();
        //     })
        //   }
        // }

        const confirm = useConfirm();

        const checkToken: any = inject('checkToken');
        const deletePhoto = () => {
            checkToken().then(() => {
                api.apiUsers.deletePhoto().then(() => {
                    store.commit('users/setPhoto', undefined);
                });
            })
        }

        const confirmDelete = (event: any) => {
            confirm.require({
                target: event.currentTarget,
                message: 'Вы действительно хотите удалить фотографию?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    deletePhoto();
                },
                reject: () => {
                    //callback to execute when user rejects the action
                }
            });
        }

        // const statYear = ref([2021, 2022, 2023]);


        // const selectedYear = ref(new Date().getFullYear());

        // const selectedChart = ref({name: 'Колличество очков по месяцам', value: 'point'});
        // const charts = ref([{name: 'Колличество очков по месяцам', value: 'point'}, {name: 'Колличество игр по месяцам', value: 'game'}])

        return {
            user,
            // chartDataGames,
            // chartDataPoints,
            // chartOptions,
            // myChartPoints,
            // myChartGames,
            showAddImage,
            // palitraPlace,
            // volumePlace,
            photoSrc,
            // statYear,
            // selectedYear,
            // selectedChart,
            // charts,
            confirmDelete,
            // loadData
        }
    }
});
