
import {computed, defineComponent, onMounted, provide, ref} from 'vue';
import Landing1 from "@/components/home/landing-1.vue";
import Landing2 from "@/components/home/landing-2.vue";
import Landing3 from "@/components/home/landing-3.vue";
import Landing4 from "@/components/home/landing-4.vue";

export default defineComponent({
    name: 'Home',
    components: {Landing1, Landing2, Landing3, Landing4},
    setup() {
        onMounted(() => {
            homePage.value.addEventListener('scroll', onScroll, true);
            // homePage.value.addEventListener('touchmove', onScroll, true); // mobile
        })

        const backScrollValue = ref(0);
        const offsetHeight = computed(() => homePage?.value?.offsetHeight ?? 0);
        const isScrolling = ref(false);

        const onScroll = (e: any) => {
            const scroll = e.target.scrollTop;
            const scrollDown = scroll > backScrollValue.value;
            const heightScroll = offsetHeight.value;

            if (scrollDown) {
                if (scroll > 80 && scroll < heightScroll && !isScrolling.value) {
                    isScrolling.value = true;
                    homePage.value.scrollTo({top: heightScroll,  behavior: 'smooth'});
                }
                if (scroll === heightScroll) {
                    isScrolling.value = false;
                }

                if (scroll > heightScroll + 80 && scroll < heightScroll * 2 && !isScrolling.value) {
                    isScrolling.value = true;
                    homePage.value.scrollTo({top: heightScroll * 2,  behavior: 'smooth'});
                }
                if (scroll === heightScroll * 2) {
                    isScrolling.value = false;
                }

                if (scroll > heightScroll * 2 + 80 && scroll < heightScroll * 3 && !isScrolling.value) {
                    isScrolling.value = true;
                    homePage.value.scrollTo({top: heightScroll * 3,  behavior: 'smooth'});
                }
                if (scroll === heightScroll * 3) {
                    isScrolling.value = false;
                }
            } else {
                if (scroll < heightScroll * 3 - 80 && scroll > heightScroll * 2 && !isScrolling.value) {
                    isScrolling.value = true;
                    homePage.value.scrollTo({top: heightScroll * 2,  behavior: 'smooth'});
                }
                if (scroll === heightScroll * 2) {
                    isScrolling.value = false;
                }

                if (scroll < heightScroll * 2 - 80 && scroll > heightScroll && !isScrolling.value) {
                    isScrolling.value = true;
                    homePage.value.scrollTo({top: heightScroll,  behavior: 'smooth'});
                }
                if (scroll === heightScroll) {
                    isScrolling.value = false;
                }

                if (scroll < heightScroll - 80 && scroll > 0 && !isScrolling.value) {
                    isScrolling.value = true;
                    homePage.value.scrollTo({top: 0,  behavior: 'smooth'});
                }
                if (scroll === 0) {
                    isScrolling.value = false;
                }
            }

            backScrollValue.value = scroll;
        }

        /* region REFS */
        const p1 = ref();
        provide('p1', p1);
        const p2 = ref();
        provide('p2', p2);
        const p3 = ref();
        provide('p3', p3);
        const p4 = ref();
        provide('p4', p4);
        const homePage: any = ref();
        /* endregion */

        const nextPage = async (val: string) => {
            // Перемотка
            isScrolling.value = true;
            switch (val) {
                case 'p2':
                    p2.value.scrollIntoView({behavior: 'smooth'});
                    break;
                case 'p3':
                    p3.value.scrollIntoView({behavior: 'smooth'});
                    break;
                case 'p4':
                    p4.value.scrollIntoView({behavior: 'smooth'});
                    break;
            }
        }

        return {
            p1, p2, p3, p4, homePage,
            nextPage
        }
    }
});
