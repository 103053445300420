import { IAuditState } from "@/store/modules/audit/types";

const state: IAuditState = {
    auditItems: [],
    count: 0,
    isError: false,
    isLoading: false,
    usersOnline: [],
};

export default state