import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09d1073c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "homePage",
  class: "home-page z-w-100 flex align-items-center justify-content-start flex-column"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_landing_1 = _resolveComponent("landing-1")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_landing_1, {
      onNextPage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({name: 'Games'})))
    })
  ], 512))
}