
import {defineComponent, inject, onMounted, ref, watch} from "vue";
import {TApi} from "@/api/types";

export default defineComponent({
    name: "UsersStatistick",
    props: {
        user_id: {
            type: String
        }
    },
    setup(props) {

        onMounted(() => {
            if (props.user_id) {
                loadData();
            }
        })

        watch(() => props.user_id, () => {
            loadData();
        })

        const palitraPlace = ref([0, 0]);
        const volumePlace = ref([0, 0]);
        const blocked = inject('blocked', ref(false));
        const statYear = ref([2021, 2022, 2023]);
        const selectedYear = ref(new Date().getFullYear());
        const selectedChart = ref({name: 'Колличество очков по месяцам', value: 'point'});
        const charts = ref([{name: 'Колличество очков по месяцам', value: 'point'}, {name: 'Колличество игр по месяцам', value: 'game'}]);
        const api: TApi = inject('api') as TApi;
        const myChartPoints: any = ref(null);
        const myChartGames: any = ref(null);
        const chartOptions = ref({
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        });
        const chartDataGames = ref({
            labels: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
            datasets: [
                {
                    type: 'line',
                    label: 'Объем кол-во игр',
                    borderColor: '#42A5F5',
                    borderWidth: 2,
                    fill: false,
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                },
                {
                    type: 'line',
                    label: 'Палитра кол-во игр',
                    borderColor: '#ad42f5',
                    borderWidth: 2,
                    fill: false,
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                }
            ]
        });
        const chartDataPoints = ref({
            labels: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
            datasets: [
                {
                    type: 'bar',
                    label: 'Объем кол-во очков',
                    backgroundColor: 'rgba(102,187,106,0.53)',
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    borderColor: '#5e5e5e',
                    borderWidth: 2
                },
                {
                    type: 'bar',
                    label: 'Палитра кол-во очков',
                    backgroundColor: 'rgba(255,167,38,0.53)',
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    borderColor: '#5e5e5e',
                    borderWidth: 2
                }
            ]
        });


        const checkToken: any = inject('checkToken');

        const loadData = async () => {
            blocked.value = true;
            if (props.user_id) {
                const payload = {
                    user_id: props.user_id,
                    year: selectedYear.value
                };
                await checkToken().then(() => {
                    api.apiGame.getUserStatistics(payload).then((res) => {
                        chartDataGames.value.datasets[0].data = res.data.game_volume.games;
                        chartDataGames.value.datasets[1].data = res.data.game_palitra.games;
                        chartDataPoints.value.datasets[0].data = res.data.game_volume.points;
                        chartDataPoints.value.datasets[1].data = res.data.game_palitra.points;
                        volumePlace.value = res.data.game_volume.place;
                        palitraPlace.value = res.data.game_palitra.place;
                        statYear.value = res.data.years;
                        blocked.value = false;
                    });
                });
            }
        }


        return {
            palitraPlace,
            volumePlace,
            selectedYear,
            selectedChart,
            charts,
            statYear,
            loadData,
            chartOptions,
            chartDataPoints,
            chartDataGames,
        }
    }
});
