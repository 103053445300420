
import {defineComponent, inject, ref} from "vue";

export default defineComponent({
  name: "landing-4",
  setup() {
    const p4 = inject('p4', ref());

    return {
      p4
    }
  }
})
