import {ActionContext} from 'vuex';
import {IGamesState} from './types';
import {IRootState} from '../../types';
import {AxiosError, AxiosResponse} from "axios";
import api from "@/api";
import {TGamesClassifierResponce} from "@/api/modules/game/types";

type UsersContext = ActionContext<IGamesState, IRootState>;

export const actions = {
    async loadGamesClassifier(context: UsersContext): Promise<any> {
        return new Promise((resolve, reject) => {
            api.apiGame.getGamesClassifier().then(
                (res: AxiosResponse) => {
                    const payload: TGamesClassifierResponce = res.data;
                    context.commit('setGamesClassifier', payload);
                    context.commit('setGameClassifierCode', payload);
                    resolve(200);
                }
            ).catch(
                (err: AxiosError) => {
                    reject(err);
                }
            );
        })
    },
};

export default actions