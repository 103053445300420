import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import store from "@/store";
import {IUser} from "@/store/modules/login/types";


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/games/',
    name: 'Games',
    component: () => import(/* webpackChunkName: "Games" */ '../views/Games.vue')
  },
  {
    path: '/game/:game_id',
    name: 'GamesWithID',
    component: () => import(/* webpackChunkName: "GameWithID" */ '../views/GameWithID.vue')
  },
  {
    path: '/game_intro/:game_id',
    name: 'GamesIntroWithID',
    props: true,
    component: () => import(/* webpackChunkName: "GameIntroWithID" */ '../views/GameIntroWithID.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  // {
  //   path: '/users',
  //   name: 'Users',
  //   component: () => import(/* webpackChunkName: "Users" */ '../views/Users.vue')
  // },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import(/* webpackChunkName: "Contacts" */ '../views/Contacts.vue')
  },
  {
    path: '/admin',
    redirect: '/admin/users',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "Admin" */ '../views/Admin.vue'),
    children: [
      {
        path: 'users',
        name: 'Admin.Users',
        component: () => import(/* webpackChunkName: "Users" */ '../views/Users.vue')
      },
      {
        path: 'audit',
        name: 'Admin.Audit',
        component: () => import(/* webpackChunkName: "Reports" */ '../views/Audit.vue')
      },
      {
        path: 'statistic',
        name: 'Admin.Statistic',
        component: () => import(/* webpackChunkName: "Statistic" */ '../views/Statistic.vue')
      },
    ]
  },
  { path: '/:pathMatch(.*)*', redirect: '/' }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const userLogin: IUser = store.getters['login/getUser'];
  const userUsers: IUser = store.getters['users/getCurrentUser'];
  const isAdmin = userLogin?.is_admin || userUsers?.is_admin;
  const privatePages = ['/users']
  const authPrivateRequired = privatePages.includes(to.path);
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const access_token = JSON.parse(localStorage.getItem('access_token') as string)
  const refresh_token = JSON.parse(localStorage.getItem('refresh_token') as string)
  if (authPrivateRequired) {
    if (authRequired && !access_token?.token && !refresh_token?.token) {
      return next('/login');
    } else if (authRequired && isAdmin && access_token?.token && refresh_token?.token) {
      return next();
    } else {
      next('/');
    }
  } else {
    if (authRequired && !access_token?.token && !refresh_token?.token) {
      return next('/login');
    } else {
      return next()
    }
  }

})

export default router
