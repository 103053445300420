import {IAudit, IAuditState} from './types';
import {TAuditUserOnline} from "@/api/modules/audit/types";

const getters = {
    getAudit: (state: IAuditState): IAudit[] | []  => state.auditItems,
    getCount: (state: IAuditState): number  => state.count,
    getLoading: (state: IAuditState): boolean  => state.isLoading,
    getError: (state: IAuditState): boolean  => state.isError,
    getUsersOnline: (state: IAuditState): TAuditUserOnline[] | []  => state.usersOnline,
    getCountUsersOnline: (state: IAuditState): number  => state.usersOnline.length,
}

export default getters