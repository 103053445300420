import {ILoginState, TSignInResponse} from './types';


const mutations = {
    signInRequest(state: ILoginState): void {
        state.isLoading = true;
    },
    signIn(state: ILoginState, payload: TSignInResponse): void {
        state.isError = false;
        state.user = payload.user;
        state.isLoading = false;
    },
    signError(state: ILoginState): void {
        state.isError = true;
        state.user = undefined;
        state.isLoading = false;
        state.date_access = undefined;
        state.date_refresh = undefined;
        state.expire_access_token = undefined;
        state.expire_refresh_token = undefined;
    },
    signOut(state: ILoginState): void {
        state.isError = false;
        state.user = undefined;
        state.isLoading = false;
        state.access_token = '';
        state.refresh_token = '';
        state.date_access = undefined;
        state.date_refresh = undefined;
        state.expire_access_token = undefined;
        state.expire_refresh_token = undefined;
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
    },
    setAccessToken(state: ILoginState, payload: string): void {
        state.access_token = payload;
    },
    setRefreshToken(state: ILoginState, payload: string): void {
        state.refresh_token = payload;
    },
    setDateAccess(state: ILoginState, payload: string): void {
        state.date_access = payload;
    },
    setDateRefresh(state: ILoginState, payload: string): void {
        state.date_refresh = payload;
    },
    setExpireAccessToken(state: ILoginState, payload: string): void {
        state.expire_access_token = payload;
    },
    setExpireRefreshToken(state: ILoginState, payload: string): void {
        state.expire_refresh_token = payload;
    },
};

export default mutations