
import {defineComponent, inject, ref} from "vue";
import card from "@/components/UI/card.vue";

export default defineComponent({
    name: "landing-2",
    components: { card },
    setup() {
        const p2 = inject('p2', ref());

        return {
            p2
        }
    }
})
