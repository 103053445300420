import { IUsersState } from "@/store/modules/users/types";

const state: IUsersState = {
    users: [],
    count: 0,
    currentUser: undefined,
    isError: false,
    isLoading: false,
    photo: undefined,
};

export default state