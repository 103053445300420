import axios from 'axios'

const instanceBase = axios.create({
    baseURL: '/api/v1/',
    withCredentials: true,
    headers: {
        'Accept': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded'
        'dataType': 'json',
        'Content-Type': 'application/json'
    }
});

export const instanceFiles = axios.create({
    baseURL: '/api/v1/',
    responseType: 'blob',
});


const instanceRefresh = axios.create({
    baseURL: '/api/v1/',
    withCredentials: true,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
    }
});

instanceRefresh.interceptors.request.use((config) => {
    const refresh_token = JSON.parse(localStorage.getItem('refresh_token') as string)?.token ?? ''
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    config.headers.Authorization =  refresh_token ? `Bearer ${refresh_token}` : '';

    return config
});

instanceBase.interceptors.request.use((config) => {
    const access_token = JSON.parse(localStorage.getItem('access_token') as string)?.token ?? ''
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    config.headers.Authorization =  access_token ? `Bearer ${access_token}` : '';
    return config
});

instanceFiles.interceptors.request.use((config) => {
    const access_token = JSON.parse(localStorage.getItem('access_token') as string)?.token ?? ''
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    config.headers.Authorization =  access_token ? `Bearer ${access_token}` : '';
    return config
});

export const instanceRef = instanceRefresh


export const instance = instanceBase

export const instanceFile = instanceFiles
