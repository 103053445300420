
import {defineComponent, inject, ref} from 'vue';
import User from "@/components/header/User.vue";


export default defineComponent({
    name: 'GameHeader',
    components: { User },
    props: {
        itemMenu: {
            type: Array,
        },
    },
    setup() {


        const op: any = ref();

        const togle = (event: any) => {
            op.value.toggle(event);
        }
        const userExit = inject('userExit');

        return {
            op,
            userExit,
            togle
        }
    }
});

