<template>
  <div class="z-img-cropper flex justify-content-start align-items-start z-w-100 mt-3 gap-3 p-3">
    <div class="img-container z-img-shadow">
      <img ref="image" alt="Изображение" :src="src" crossorigin>
    </div>
    <img :src="destination" alt="Изображение" class="img-preview z-img-shadow">
    <Avatar :image="AvatarImage"  class="z-img-shadow" size="xlarge" shape="circle" />
  </div>
</template>

<script>
import {defineComponent, onMounted, ref, inject} from 'vue';
import Cropper from "cropperjs";

export default  defineComponent ({
  name: "ImageCropper",
  props: {
    src: String
  },
  setup() {

    onMounted(() => {

      cropper.value = new Cropper(image.value, {
        zoomable: false,
        scalable: false,
        aspectRatio: 1,
        crop: async () => {
          const canvas = cropper.value.getCroppedCanvas();
          destination.value = canvas.toDataURL("image/png");
          AvatarImage.value = destination.value;
          await makeCroppDate(destination.value);
        }
      });
    });

    async function urltoFile(url, filename, mimeType){
      return (fetch(url)
              .then(function(res){return res.arrayBuffer();})
              .then(function(buf){return new File([buf], filename,{type:mimeType});})
      );
    }

    const makeCroppDate = async (val) => {
      let file = await urltoFile(val, fileObject.value.name, fileObject.value.type);
      croppData.value = new FormData();
      croppData.value.append('file', file);
    };

    const croppData = inject('croppData');
    const fileObject = inject('fileObject');

    const cropper = ref({});
    const destination = ref({});
    const image = ref({});
    const AvatarImage = ref(null);

    return {
      cropper,
      destination,
      image,
      AvatarImage
    }
  }
});
</script>

<style lang="scss" scoped>

.img-container {
  width: 640px;
  height: 480px;
  float: left;
  z-index: 5555 !important;
}

.img-preview {
  width: 200px;
  height: 200px;
  float: left;
  margin-left: 10px;
  z-index: 5555 !important;
}

.z-img-cropper {
  min-height: 520px;
  height: 520px;
  background-color: rgba(138, 138, 138, 0.72);
}

.z-img-shadow {
  -webkit-box-shadow: 0 0 14px 8px rgba(255, 255, 255, 0.93);
  -moz-box-shadow: 0 0 14px 8px rgba(255, 255, 255, 0.93);
  box-shadow: 0 0 14px 8px rgba(255, 255, 255, 0.93);
}
</style>