import { AxiosInstance } from 'axios'
import {
    TGamePlayedPayload, TGamesAllowedCreate, TUserStatisticsPayload,
} from './types'
import {TApiGame} from "@/api/types";


export default function (instance: AxiosInstance): TApiGame {
    return {
        setGamePlayed(payload: TGamePlayedPayload) {
            const path = `/game/played`;
            return instance.post(path, payload)
        },
        getUserStatistics(payload: TUserStatisticsPayload) {
            const path = `/game/users_statistics`;
            const val = {params: payload};
            return instance.get(path, val)
        },
        getGamesClassifier() {
            const path = `/game/games_classifier`;
            return instance.get(path)
        },
        getAllowedGames(userId: string) {
            const path = `/game/games_allowed/${userId}`;
            return instance.get(path)
        },
        setAllowedGames(payload: TGamesAllowedCreate) {
            const path = '/game/games_allowed';
            // const val = {params: payload};
            return instance.post(path, payload)
        }
     }
}