import actions from '@/store/modules/login/actions';
import mutations from '@/store/modules/login/mutations';
import getters from '@/store/modules/login/getters';
import state from "@/store/modules/login/state";


export const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}

export default module