const myLocale = {
        startsWith: 'Начинается с',
        contains: 'Содержит',
        notContains: 'Не содержит',
        endsWith: 'Заканчивается на',
        equals: 'Равняется',
        notEquals: 'Не равняется',
        noFilter: 'Без фильтра',
        lt: 'Less than',
        lte: 'Less than or equal to',
        gt: 'Greater than',
        gte: 'Greater than or equal to',
        dateIs: 'Включает дату',
        dateIsNot: 'Не содержит дату',
        dateBefore: 'Раньше даты',
        dateAfter: 'Позже даты',
        clear: 'Очистить',
        apply: 'Применить',
        matchAll: 'Полное совпадение',
        matchAny: 'Частичное совпадение',
        addRule: 'Добавить правило',
        removeRule: 'Удалить правило',
        accept: 'Да',
        reject: 'Нет',
        choose: 'Выбрать',
        upload: 'Загрузка',
        cancel: 'Отменить',
        dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        dayNamesShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        monthNamesShort: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Ноя', 'Дек'],
        today: 'Сегодня',
        weekHeader: 'Wk',
        firstDayOfWeek: 1,
        dateFormat: 'mm/dd/yy',
        weak: 'Неделя',
        medium: 'Средний',
        strong: 'Значительный',
        passwordPrompt: 'Введите пароль',
        emptyFilterMessage: 'Результаты не найдены',
        emptyMessage: 'Нет доступных опций'
}

export default myLocale