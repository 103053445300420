import {createStore, StoreOptions} from "vuex";
import { IRootState } from './types';
import login from './modules/login';
import users from './modules/users';
import games from './modules/games';
import audit from "./modules/audit";
import root from './modules/root';


const storeOptions: StoreOptions<IRootState> = {
    modules: {
        root,
        login,
        users,
        audit,
        games
    }
}

export const store = createStore(storeOptions)
export default store