
import {defineComponent, inject, ref} from "vue";

export default defineComponent({
  name: "landing-3",
  setup() {
    const p3 = inject('p3', ref());

    return {
      p3
    }
  }
})
