import {IAuditState} from './types';
import {TAuditResponse, TAuditUserOnline} from "@/api/modules/audit/types";


const mutations = {
    setLoading(state: IAuditState): void {
        state.isLoading = true;
        state.isError = false;
    },
    setUsers(state: IAuditState, payload: TAuditResponse): void {
        state.isError = false;
        state.auditItems = payload.audit;
        state.count = payload.count;
        state.isLoading = false;
    },
    setError(state: IAuditState): void {
        state.isError = true;
        state.auditItems = [];
        state.count = 0;
        state.isLoading = false;
    },
    setUsersOnline(state: IAuditState, val: TAuditUserOnline[]): void {
        state.usersOnline = val;
    },
};

export default mutations