import {ActionContext} from 'vuex';
import {IUser, IUsersState, TUsersResponse} from './types';
import {IRootState} from '../../types';
import {AxiosError, AxiosResponse} from "axios";
import {TUsersPayload} from "@/api/modules/users/types";
import api from "@/api";

type UsersContext = ActionContext<IUsersState, IRootState>;

export const actions = {
    async loadUsers(context: UsersContext, payload: TUsersPayload): Promise<any> {
        context.commit('setLoading');
        return new Promise((resolve, reject) => {
            api.apiUsers.getUsers(payload).then(
                (res: AxiosResponse) => {
                    const payload: TUsersResponse = res.data;
                    context.commit('setUsers', payload);
                    resolve(200);
                }
            ).catch(
                (err: AxiosError) => {
                    context.commit('setError');
                    reject(err);
                }
            );
        });
    },
    async loadCurrentUser(context: UsersContext): Promise<any> {
        context.commit('setLoading');
        return new Promise((resolve, reject) => {
            api.apiUsers.loadUser().then(
                (res: AxiosResponse) => {
                    const payload: IUser = res.data;
                    context.commit('setCurrentUser', payload);
                    resolve(200);
                }
            ).catch(
                (err: AxiosError) => {
                    reject(err);
                }
            );
        })
    },
    async loadPhotoUser(context: UsersContext): Promise<any> {
        return new Promise((resolve, reject) => {
            api.apiUsers.getPhoto().then(
                (res: AxiosResponse) => {
                    const payload: string | any = res.data.msg;
                    context.commit('setPhoto', payload);
                    resolve(200);
                }
            ).catch(
                (err: AxiosError) => {
                    reject(err);
                }
            );
        })
    },
};

export default actions